<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="data-dialog"
		width="550px">
		<span slot="title" class="dialog-title">
			<span>证书</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<div class="ce-content" ref="content">
			<div class="top-box">
				<img src="@/assets/images/ce4.png" alt="">
				<img src="@/assets/images/ce5.png" alt="">
			</div>
			<div class="title-box">
				<p class="title1">理光认证服务工程师培训证书</p>
				<img src="@/assets/images/ce3.png" alt="">
				<p class="title2">RICOH CERTIFICATED SERVICE ENGINEER</p>
			</div>
			<div class="ce-item-div">
				所属公司：{{obj.agentName}}
			</div>
			<div class="ce-item-div">
				学员：{{obj.userName}}
			</div>
			<div class="ce-item-div">
				于：{{obj.trainStartTime.split('-')[0]}}年{{obj.trainStartTime.split('-')[1]}}月{{obj.trainStartTime.split('-')[2]}}日 至
				{{obj.trainEndTime.split('-')[0]}}年{{obj.trainEndTime.split('-')[1]}}月{{obj.trainEndTime.split('-')[2]}}日
			</div>
			<div class="ce-item-div ce-item-div1">
				<p class="title">成功通过培训课程</p>
				<!-- <p class="text">{{obj.courseName}}</p> -->
			</div>
			<p class="p2">{{obj.courseName}}</p>
			<p class="p3" v-if="obj.teacherName">（<span style="margin-right:10px;">讲师</span>{{obj.teacherName ? obj.teacherName : ''}}）</p>
			<p class="title3">特此祝贺</p>
			<p class="title4">Congratulations!</p>
			<div class="name1">
				<template v-if="modelType === 2">
					<span class="a1">生产型打印事业部</span>
					<span class="a2">总经理</span>
					<span class="a4">高方志</span>
				</template>
				<template v-else>
					<span class="a1">客户服务部</span>
					<span class="a2">总经理</span>
					<span class="a3">顾威</span>
				</template>
			</div>
			<div class="name2">
				<template v-if="modelType === 2">
					<img src="@/assets/images/2.jpg" alt="">
				</template>
				<template v-else>
					<img src="@/assets/images/1.jpg" alt="">
				</template>
			</div>
			<div class="bottom-box">
				<div class="right">
					<img class="zhang" src="@/assets/images/ce6.png" alt="">
					<p>理光（中国）投资有限公司</p>
				</div>
			</div>
			<p class="p1" v-if="obj.eduPlanCode">教育计划编号：<span>{{obj.eduPlanCode ? obj.eduPlanCode : ''}}</span></p>
		</div>
	</el-dialog>
</template>
<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
	data() {
		return {
			visible: false,
			obj: {
				trainStartTime: '--',
				trainEndTime: '--'
			}
		}
	},
	computed: {
		modelType() {
			// 1: 原版;  2: 生产型打印设备, 工程机模板
			const depList = ['生产型打印设备', '工程机']
			if (depList.includes(this.obj.courseTypeNames)) return 2
			return 1
		}
	},
	methods: {
		download(data) {
			this.visible = true;
			this.$nextTick(() => {
				this.obj = data;
				setTimeout(()=> {
					this.htmlChange(this.$refs.content,'证书');
				},50)
			})

		},
		htmlChange(html,name){
			var title = name;
			var opts = {
				scale: 4,
				dpi: window.devicePixelRatio * 4,
				allowTaint: true,
				useCORS: true
			};
			let that = this;
			html2Canvas(html,opts).then(function (canvas) {
				// let contentWidth = canvas.width
				// let contentHeight = canvas.height
				// let pageHeight = contentWidth / 592.28 * 841.89
				// let leftHeight = contentHeight
				// let position = 0
				let imgWidth = 595.28
				// let imgHeight = 592.28 / contentWidth * contentHeight
				// console.log(imgHeight)
				// 写死正好一页的高度，计算可以会超出一页高度
				let imgHeight = 860
				let pageData = canvas.toDataURL('image/jpeg', 1.0)
				let PDF = new JsPDF('', 'pt', 'a4')
				PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
				// if (leftHeight < pageHeight) {
				// 	PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
				// } else {
				// 	while (leftHeight > 0) {
				// 		PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
				// 		leftHeight -= pageHeight
				// 		position -= 841.89
				// 		if (leftHeight > 0) {
				// 			PDF.addPage()
				// 		}
				// 	}
				// }
				PDF.save(title + '.pdf')
				that.visible = false;
			})
      	},
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.data-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.data-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.ce-content{
	box-sizing: border-box;
	padding: 20px;
	position: relative;
	background: url('~@/assets/images/ce1.png') no-repeat;
	background-position-y: 50px;
    background-size: 100%;
	.top-box{
		margin-bottom: 15px;
		img:first-child{
			width: 80px;
		}
		img:last-child{
			width: 65px;
			float: right;
		}
	}
	.title-box{
		margin-bottom: 15px;
		.title1{
			text-align: center;
			font-size: 24px;
			font-weight: bold;
			color: #000;
		}
		.title2{
			text-align: center;
			font-size: 16px;
			font-weight: bold;
			color: #000;
		}
		img{
			display: block;
			margin: 0 auto;
			width: 330px;
		}
	}
	.ce-item-div{
		display: flex;
		min-height: 36px;
		line-height: 36px;
		background: url('~@/assets/images/ce2.png') no-repeat 100%;
		background-size: cover;
		box-sizing: border-box;
		padding-left: 10px;
		border-radius: 5px;
		color: #888;
		font-weight: bold;
		margin-bottom: 25px;
		font-size: 13px;
		.title{
			width: 130px;
		}
		.text{
			flex: 1;
		}
	}
	.ce-item-div1{
		line-height: 16px;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 0;
	}
	.title3{
		box-sizing: border-box;
		padding-left: 10px;
		font-size: 16px;
		color: #000;
		margin-bottom: 10px;
	}
	.title4{
		box-sizing: border-box;
		padding-left: 10px;
		font-size: 18px;
		font-weight: bold;
		color: #000;
		margin-bottom: 10px;
	}
	.bottom-box{
		height: 160px;
		.right{
			float: right;
			width: 50%;
			height: 100%;
			color: #000;
			font-size: 18px;
			font-weight: bold;
			position: relative;
			.title{
				line-height: 32px;
				span:last-child{
					width: 60%;
					display: inline-block;
					border-bottom: 2px solid #000;
				}
			}
			p{
				position: absolute;
				bottom: 77px;
				right: 22px;
				font-size: 15px;
			}
			.zhang{
				position: absolute;
				top: 10px;
				left: 55px;
				width: 150px;
			}
		}
	}
	.p1{
		padding: 0 0 15px;
		font-size: 13px;
		span{
			font-weight: bold;
		}
	}
	.p2,.p3{
		text-align: center;
		color: #000;
		font-weight: bold;
		font-size: 15px;
	}
	.name1{
		text-align: right;
		font-size: 16px;
		font-weight: bold;
		color: #000;
		.a1{
			margin-right: 20px;
		}
		.a2{
			margin-right: 40px;
		}
		.a3{
			margin-right: 88px;
		}
		.a4{
			margin-right: 70px;
		}
	}
	.name2{
		text-align: right;
		img{
			width: 100px;
			border-bottom: 1px solid #000;
			margin-right: 50px;
		}
	}
}
</style>
